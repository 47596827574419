
export const homeObjOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'Get In Touch',
    description:
      'Email : support@floresinnovatives.com',
    
    imgStart: '',
    start: ''
  };
  
  export const homeObjTwo = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Artificial intelligence (AI)',
    headline: 'Technology we use',
    description:
      "Artificial intelligence (AI) is a wide-ranging tool that enables people to rethink how we integrate information, analyze data, and use the resulting insights to improve decision making—and already it is transforming every walk of life. In this report, Darrell West and John Allen discuss AI's application across a variety of sectors, address issues in its development, and offer recommendations for getting the most out of AI while still protecting important human values.",
    buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/home-logo-2.svg'),
    alt: 'Vault',
    start: ''
  };
  
  export const homeObjThree = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Our culture',
    headline:
      'Why US',
    description:
      "Technologies is a young and rapidly growing team with a passion for leveraging on technology to solve healthcare issues. With an average age of less than 30 years old, the environment is fun and ever-evolving. We value outside of the box thinking and radical ideas to push the company forward. Independence and Entrepreneurial spirit are the core values we seek in our colleagues (we are not fans of mindless drones waiting for instructions!)",
    buttonLabel: 'View Case Study',
    imgStart: 'start',
    img: require('../../images/log1.png'),
    alt: 'Vault',
    start: 'true'
  };
  
  export const homeObjFour = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Secure Database',
    headline: 'All your data is stored on our secure server',
    description:
      'You will never have to worry about your information getting leaked. Our team of security experts will ensure your records are kept safe.',
    buttonLabel: 'Sign Up Now',
    imgStart: 'start',
    img: require('../../images/info-sec.svg'),
    alt: 'Vault',
    start: 'true'
  };
  