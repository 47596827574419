import React from 'react';

import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaLinkedin
} from 'react-icons/fa';
import {
  FooterContainer,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  
  WebsiteRights,
  SocialIcons,
  SocialIconLink
} from './Footer.elements';


function Footer() {
  return (
    <FooterContainer>
      <FooterLinksContainer>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkTitle>About Us</FooterLinkTitle>
            <FooterLink to='/About'>Why us</FooterLink>
            <FooterLink to='/About'>Careers</FooterLink>
            <FooterLink to='/About'>Investors</FooterLink>
            <FooterLink to='/About'>Terms of Service</FooterLink>
          </FooterLinkItems>
          <FooterLinkItems>
            <FooterLinkTitle>Contact Us</FooterLinkTitle>
            <FooterLink to='/Contactus'>Contact</FooterLink>
            <FooterLink to='/Contactus'>Support</FooterLink>
            <FooterLink to='/Contactus'>Destinations</FooterLink>
            <FooterLink to='/Contactus'>Sponsorships</FooterLink>
          </FooterLinkItems>
        </FooterLinksWrapper>
      </FooterLinksContainer>
      <SocialMedia>
        <SocialMediaWrap>
          <SocialLogo to='/'>
            Flores Innovative Solutions
          </SocialLogo>
          <WebsiteRights>Flores Innovative Solutions © 2022</WebsiteRights>
          <SocialIcons>
            <SocialIconLink href='https://www.facebook.com/' target='on_click' aria-label='Facebook'>
              <FaFacebook />
            </SocialIconLink>
            <SocialIconLink href='https://www.instagram.com/?hl=en' target='on_click' aria-label='Instagram'>
              <FaInstagram />
            </SocialIconLink>
            
            <SocialIconLink href='https://twitter.com/' target='on_click' aria-label='Twitter'>
              <FaTwitter />
            </SocialIconLink>
            <SocialIconLink href='https://www.linkedin.com/company/flore-innovative-solutions-private-limt/?viewAsMember=true' target='on_click' aria-label='LinkedIn'>
              <FaLinkedin />
            </SocialIconLink>
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  );
}

export default Footer;
