import React from 'react';
import { InfoSection } from '../../components';
import { homeObjOne } from './Data';

function Contactus() {
  return (
    <>
      <InfoSection {...homeObjOne} />
      
      
    </>
  );
}

export default Contactus;
