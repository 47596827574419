import React from 'react';
// import { Button } from '../../globalStyles';
// import { AiFillThunderbolt } from 'react-icons/ai';
// import { GiCrystalBars } from 'react-icons/gi';
// import { GiCutDiamond, GiRock } from 'react-icons/gi';
// import { GiFloatingCrystal } from 'react-icons/gi';
// import { IconContext } from 'react-icons/lib';
import health from './health.svg'
import webimg from './web.svg'
import bpoimg from './bpo.svg'

import {
  PricingSection,
  PricingWrapper,
  PricingHeading,
  PricingContainer,
  PricingCard,
  PricingCardInfo,
  
  PricingCardPlan,
  
} from './Pricing.elements';

function Pricing() {
  return (
    
      <PricingSection>
        <PricingWrapper>
          <PricingHeading>Our Services</PricingHeading>
          <PricingContainer>
            <PricingCard to='/'>
              <PricingCardInfo>
                <PricingCardPlan>Health Care</PricingCardPlan>
                <img src = {health}
                height={150}
                width = {150}
                ></img>
                <p>We provide clinical management systems (CMS) for clinics and Hospital, which provides end to end services, provide web applications, website and mobile applications for hospitals and users like patients, physicians.
We maintain clinics data and provide digital documentation to ease physician work ,make transparency and patient trust</p>
                
              </PricingCardInfo>
            </PricingCard>
            <PricingCard to='/'>
              <PricingCardInfo>
                <PricingCardPlan>BPO</PricingCardPlan>
                
                <img src = {bpoimg}
                height={150}
                width = {150}
                ></img>
                <p>We provide customer supporting services for clients like make a calls behalf of hospitals, clinics and e-commerce businesses and retailers.</p>
              </PricingCardInfo>
            </PricingCard>
            <PricingCard to='/'>
              <PricingCardInfo>
                <PricingCardPlan>Professional, Personal Solutions</PricingCardPlan>
                
                <img src = {webimg}
                height={150}
                width = {150}
                ></img>
                <p>At our company, we turn the digital dreams of our clients into a reality. We work closely with our users throughout development to ensure that we are still aligned with the end-goal. We are committed to producing exceptional software for each of our clients.</p>
              </PricingCardInfo>
            </PricingCard>
          </PricingContainer>
        </PricingWrapper>
      </PricingSection>
    
  );
}
export default Pricing;
