import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes} from 'react-icons/fa';
import { GiArtificialIntelligence } from "react-icons/gi";
//import { IconContext } from 'react-icons/lib';
//import { Button } from '../../globalStyles';
import {
  Nav,
  NavbarContainer,
  NavLogo,
  
  MobileIcon,
  NavMenu,
  NavItem,
  
  NavLinks,
 
} from './Navbar.elements';



function Navbar() {
  const [click, setClick] = useState(false);
  // [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  // const showButton = () => {
  //   if (window.innerWidth <= 960) {
  //     setButton(false);
  //   } else {
  //     setButton(true);
  //   }
  // };

  // useEffect(() => {
  //   showButton();
  // }, []);

  // window.addEventListener('resize', showButton);

  return (
    <>
      
        <Nav>
          <NavbarContainer>
            <NavLogo to='/' onClick={closeMobileMenu}>
              <GiArtificialIntelligence></GiArtificialIntelligence>
              Flores Innovative Solutions
            </NavLogo>
            <MobileIcon onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </MobileIcon>
            <NavMenu onClick={handleClick} click={click}>
              <NavItem>
                <NavLinks to='/' onClick={closeMobileMenu}>
                  Home
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to='/services' onClick={closeMobileMenu}>
                  Services
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to='/Contactus' onClick={closeMobileMenu}>
                  Contact Us
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to='/About' onClick={closeMobileMenu}>
                  About
                </NavLinks>
              </NavItem>
            </NavMenu>
          </NavbarContainer>
        </Nav>
     
    </>
  );
}

export default Navbar;
