import React from 'react';
import { InfoSection } from '../../components';
import { homeObjTwo, homeObjThree } from './Data';

function About() {
  return (
    <>
      <InfoSection {...homeObjThree} />
      <InfoSection {...homeObjTwo} />
      
    </>
  );
}

export default About;
